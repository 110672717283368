@import "./../../../variables.scss";

.home-container {
  .welcome-container {
    display: flex;
    flex-direction: row;
    padding-left: 6em;
    padding-right: 6em;
    padding-top: 40px;
    gap: 40px;
    border-bottom: 1px solid var(--chakra-colors-divider_color);
    height: 70vh;
    @include media-breakpoint-down(lg) {
      padding: 30px 10px;
      flex-direction: column;
    }
    .welcome-info {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      max-width: 650px;
      @include media-breakpoint-down(lg) {
        max-width: 100%;
        align-items: center;
      }
      .title {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -0.05em;
        margin: 0;
        @include media-breakpoint-down(lg) {
          font-size: 30px;
          text-align: center;
        }
      }
      .description {
        font-size: 20px;
        color: var(--chakra-colors-grey_text_color);
        @include media-breakpoint-down(lg) {
          font-size: 15px;
          text-align: center;
        }
      }
    }
    .welcome-images {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      @include media-breakpoint-down(lg) {
        justify-content: center;
      }
      .splide {
        padding: 0;
      }
      .carousel-image {
        border-radius: 8px;
        width: 600px;
        height: 360px;
        object-fit: cover;
        aspect-ratio: 16 / 9;
      }
    }
  }
  .interesting-properties {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    border-bottom: 1px solid var(--chakra-colors-divider_color);
    padding-bottom: 100px;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      padding: 10px;
    }
    .title {
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.05em;
      margin: 0;
      @include media-breakpoint-down(lg) {
        font-size: 30px;
      }
    }
    .description {
      font-size: 20px;
      color: var(--chakra-colors-grey_text_color);
      @include media-breakpoint-down(lg) {
        font-size: 15px;
      }
    }

    .some-properties {
      display: flex;
      gap: 20px;
      overflow-x: auto;
      max-width: 100%;
      width: 100%;
      justify-content: center;
      padding-bottom: 10px;
      @include media-breakpoint-down(lg) {
        width: unset;
        justify-content: unset;
      }

      .card-container {
        display: flex;
        justify-content: center;
        .card-body {
          width: 400px;
          @include media-breakpoint-down(lg) {
            width: 330px;
          }
        }
        .property-image {
          width: 400px;
          height: 250px;
          object-fit: cover;
          @include media-breakpoint-down(lg) {
            width: 330px;
            height: 180px;
          }
        }
        .map-base-property-card {
          display: flex;
          height: 250px;
          width: 400px;
          @include media-breakpoint-down(lg) {
            width: 330px;
            height: 180px;
          }
          .map-container {
            width: 100%;
            height: 250px;
            border-radius: var(--chakra-radii-lg);
            @include media-breakpoint-down(lg) {
              height: 180px;
            }
          }
        }
        .feature-item-info {
          margin: 0;
          text-align: center;
          width: 100%;
        }
      }
      .features-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--chakra-colors-grey_text_color);
        font-size: 14px;
        gap: 8px;
      }

      .interes-property-btn-container {
        width: 100%;
        margin-bottom: var(--chakra-space-6);
      }
    }
  }

  .our-sales {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    border-bottom: 1px solid var(--chakra-colors-divider_color);
    padding-bottom: 100px;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      padding: 10px;
    }
    .title {
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.05em;
      margin: 0;
      @include media-breakpoint-down(lg) {
        font-size: 30px;
      }
    }
    .description {
      font-size: 20px;
      color: var(--chakra-colors-grey_text_color);
      @include media-breakpoint-down(lg) {
        font-size: 15px;
      }
    }

    .some-properties {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      padding: 24px 15%;
      @include media-breakpoint-down(lg) {
        padding: 0;
      }

      .card-container {
        display: flex;
        justify-content: center;
        .property-image {
          width: 220px;
          height: 236px;
          object-fit: cover;
          @include media-breakpoint-down(lg) {
            width: 30%;
            height: 15vh;
            display: none;
          }
        }
        .map-base-property-card {
          display: flex;
          height: 236px;
          width: 220px;
          @include media-breakpoint-down(lg) {
            width: 30%;
            height: 100%;
            display: none;
          }
          .map-container {
            width: 100%;
            height: 236px;
            border-radius: var(--chakra-radii-lg) 0 0 var(--chakra-radii-lg);
            @include media-breakpoint-down(lg) {
              height: 100%;
            }
          }
        }
      }
      .features-as-text {
        color: var(--chakra-colors-grey_text_color);
        font-size: 12px;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
      }
    }
  }
  .contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    padding-bottom: 120px;
    @include media-breakpoint-down(lg) {
      padding: 10px;
      padding-bottom: 120px;
    }
    .title {
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.05em;
      margin: 0;
      @include media-breakpoint-down(lg) {
        font-size: 30px;
      }
    }
    .description {
      font-size: 20px;
      color: var(--chakra-colors-grey_text_color);
      @include media-breakpoint-down(lg) {
        font-size: 15px;
        text-align: center;
      }
    }

    .contact-button {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }
}

.add-favorite {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 15px;
  fill: orangered;
  color: orangered;
  font-size: 24px;
  z-index: 8;
  .favorite-icon {
    cursor: pointer;
  }
}
