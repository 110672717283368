@import "./../../../variables.scss";

.links-base {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include media-breakpoint-down(lg) {
    width: 72px;
  }
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 0 60px;
  // border-bottom: 1px solid var(--chakra-colors-divider_color);
  background-color: #fffcfc;
  // background-image: url("https://www.transparenttextures.com/patterns/cutcube.png");

  background-color: var(--chakra-colors-secondary_background_color);
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;

  @include media-breakpoint-down(lg) {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
}

.light-shadow {
  box-shadow: 2px -6px 12px 4px var(--chakra-colors-divider_color);
  // border-bottom: 1px solid var(--chakra-colors-divider_color);
}
.dark-shadow {
  box-shadow: 2px -15px 12px 4px var(--chakra-colors-secondary_background_color);
  // border-bottom: 1px solid var(--chakra-colors-secondary_background_color);
}

.navbar {
  margin-left: 20px;
  &_options {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}

.user-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  @include media-breakpoint-down(lg) {
    gap: 5px;
  }
}

.mobile-links {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 72px;
  width: 100%;
  .menu-mobile-item {
    width: 100%;
    border-bottom: 2px solid;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    button {
      font-size: 25px;
      font-weight: 600;
    }
    svg {
      font-size: 25px;
    }
  }
}
