@import "./../../../variables.scss";

$gray-light: var(--chakra-colors-attachment_upload_border);
$gray: var(--chakra-colors-attachment_upload_background_hover);
$table-light-gray: var(--chakra-colors-attachment_upload_background);
$text-black: var(--chakra-colors-secondary_text_color);

.upload-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  &.uploads-disabled {
    cursor: not-allowed;
  }

  .dropzone-box-disabled-text {
    font-weight: 400;
    font-size: 12px;
    margin: auto;
    text-align: center;
  }

  .active {
    background-color: var(
      --chakra-colors-attachment_upload_background_hover
    ) !important;
  }

  .dropzone-box {
    display: flex;
    flex-direction: column;
    background-color: var(--chakra-colors-attachment_upload_background);
    border: 2px dashed var(--chakra-colors-attachment_upload_border);
    border-radius: 10px;
    width: 172px;
    min-height: 164px;
    outline: none;
    cursor: pointer;

    &.uploads-disabled {
      cursor: not-allowed;
    }

    .dropzone-icon {
      color: rgba(0, 0, 0, 0.12) !important;
      font-size: 3.5rem;
      margin: 18px auto 8px auto;
    }

    .dropzone-message {
      text-align: center;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1rem;
      padding: 8px;
    }
  }

  .upload-file-types {
    display: flex;
    flex-direction: column;

    .file-types-header {
      padding: 0 2rem 0.5rem;
      @include media-breakpoint-down(lg) {
        padding: 0 1rem 0.5rem;
      }
    }

    .file-limits {
      color: var(--chakra-colors-secondary_text_color);
      margin: 0 0 0 2rem;
      text-transform: capitalize;
      @include media-breakpoint-down(lg) {
        margin: auto auto auto 0px;
        margin: 0 0 0 1rem;
      }
    }

    .file-types-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0px 0 0rem 1rem;

      .file-types {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        @include media-breakpoint-down(lg) {
          gap: 5px;
        }

        .file-type {
          min-width: 56.6px;
          text-align: center;
          color: var(--chakra-colors-file_types_color);
          border: 1px solid var(--chakra-colors-file_types_color);
          border-radius: 5px;
          margin: auto auto auto 16px;
          padding: 3px 10px;
          font-weight: 600;
          @include media-breakpoint-down(lg) {
            margin: auto auto auto 0px;
          }
        }
      }
    }
  }
  .uploaded-file-list {
    display: flex;
    flex-direction: column;
    margin-left: 12px !important;
    .no-pending-attachments {
      margin: auto 0 auto 5rem;
    }
  }
}

.upload-container-dark {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  &.uploads-disabled {
    cursor: not-allowed;
  }

  .dropzone-box-disabled-text {
    font-weight: 400;
    font-size: 12px;
    margin: auto;
    text-align: center;
  }

  .active {
    background-color: var(--chakra-colors-gray-600) !important;
  }

  .dropzone-box {
    display: flex;
    flex-direction: column;
    background-color: var(--chakra-colors-gray-800);
    border: 2px dashed var(--chakra-colors-white);
    border-radius: 10px;
    width: 172px;
    min-height: 164px;
    outline: none;
    cursor: pointer;

    &.uploads-disabled {
      cursor: not-allowed;
      background-color: var(--chakra-colors-gray-800);
    }

    .dropzone-icon {
      color: var(--chakra-colors-white) !important;
      font-size: 3.5rem;
      margin: 18px auto 8px auto;
    }

    .dropzone-message {
      text-align: center;
      color: var(--chakra-colors-white);
      font-size: 1rem;
      padding: 8px;
    }
  }

  .upload-file-types {
    display: flex;
    flex-direction: column;

    .file-types-header {
      padding: 0 2rem 0.5rem;
      @include media-breakpoint-down(lg) {
        padding: 0 1rem 0.5rem;
      }
    }

    .file-limits {
      color: var(--chakra-colors-white);
      margin: 0 0 0 2rem;
      text-transform: capitalize;
      @include media-breakpoint-down(lg) {
        margin: 0 0 0 1rem;
      }
    }

    .file-types-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0px 0 0rem 1rem;

      .file-types {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        @include media-breakpoint-down(lg) {
          gap: 5px;
        }

        .file-type {
          min-width: 56.6px;
          text-align: center;
          color: var(--chakra-colors-white);
          border: 1px solid var(--chakra-colors-white);
          border-radius: 5px;
          margin: auto auto auto 16px;
          padding: 3px 10px;
          font-weight: 600;
          @include media-breakpoint-down(lg) {
            margin: auto auto auto 0px;
          }
        }
      }
    }
  }
  .uploaded-file-list {
    display: flex;
    flex-direction: column;
    margin-left: 12px !important;
    .no-pending-attachments {
      margin: auto 0 auto 5rem;
    }
  }
}

.attachments {
  .list-group-item {
    border: 1px solid blue;
  }
}

.clickable-attachment:hover {
  color: var(--chakra-colors-secondary_text_color) !important;
}

.attachment {
  $_attachment-width: 60px;
  margin-top: 1rem;

  // NOTE! Overrides for the package 'react-file-previewer'
  .thumbnail-wrapper {
    img,
    canvas {
      display: block;
      max-width: $_attachment-width;
      height: auto;
    }
  }

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: $_attachment-width;

  &__title--shorten {
    width: $_attachment-width;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__remove {
    position: absolute;
    top: -10px;
    right: -9px;
    z-index: 1;

    cursor: pointer;

    &--bg-white {
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.component-base {
  display: flex;
  flex-direction: column;
  gap: 30px;
  @include media-breakpoint-down(lg) {
    margin: auto auto auto 0px;
    gap: 8px;
  }
}
.files-base {
  display: flex;
  flex-direction: row;
  gap: 30px;
  overflow-x: auto;
}

.attachment {
  #labelText {
    text {
      font-size: 10px;
    }
  }
}
