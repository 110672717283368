@import "./../../../../../variables.scss";

.add-new-feature {
  display: flex;
  gap: 12px;
  margin: 20px 0;
  color: var(--chakra-colors-secondary_color) !important;
  fill: var(--chakra-colors-secondary_color) !important;
  font-size: 18px;
  align-items: center;
  background-color: transparent !important;
  padding: 0 !important;

  .addFeature-label {
    font-weight: 600;
    margin: 0;
  }
}
.all-features {
  width: 50%;
  margin-top: 24px;
}
.feature-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 0;
  padding: 0;
  gap: 12px;
  width: 100%;

  .feature-type {
    width: 60%;
  }
  .input-container {
    width: 40% !important;
  }
}

.remove-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--chakra-colors-delete_color);
  height: 40px;
}
