@import "./../../../variables.scss";
.contact-info {
  width: 50%;
  height: 750px;
  display: flex;
  flex-direction: row;
  box-shadow: 4px 5px 12px -3px rgba(0, 0, 0, 0.75);
  border-radius: 8px;

  @include media-breakpoint-down(xl) {
    box-shadow: none;
    width: 100%;
  }

  &-left {
    width: 50%;
    background-color: var(--chakra-colors-secondary_color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 0 0 8px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(xl) {
      display: none;
    }
    .contact-text {
      margin: 0;
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 800;
      color: var(--chakra-colors-white);
      text-align: center;
    }
  }

  &-right {
    padding: 16px 20px;
    width: 50%;
    border: 2px solid var(--chakra-colors-secondary_color);
    border-radius: 0 8px 8px 0;
    background-color: var(--chakra-colors-white);
    @include media-breakpoint-down(xl) {
      border: none;
      width: 100%;
      border-radius: unset;
    }
    .contact-title {
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 12px;
    }
  }
}
.contact-base {
  .contact-container {
    margin-top: $top-margin;
    height: 92.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.688);
    @include media-breakpoint-down(xl) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.no-resize {
  resize: none;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 93%;
}

.sections-3 {
  height: 33.3%;
}

.center-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.down-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  padding: 16px 20px;
  gap: 30px;
}

.link-with-icon {
  text-decoration: none;
  color: var(--chakra-colors-primary_color);
  font-size: 14px;
  font-weight: 600;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    .link-icon {
      width: 18px;
      height: 18px;
      color: var(--chakra-colors-primary_color);
      fill: var(--chakra-colors-primary_color);
    }
  }
}

.sending-email {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  z-index: 999;
}
