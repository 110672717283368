.spinner-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
  > p {
    font-weight: bold;
  }
}
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: var(--chakra-colors-secondary_color);
  box-shadow: 30px 0 0 var(--chakra-colors-primary_color);
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: var(--chakra-colors-primary_color);
  box-shadow: 30px 0 0 var(--chakra-colors-secondary_color);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--chakra-colors-primary_color);
  }
  50% {
    box-shadow: 0 0 0 var(--chakra-colors-primary_color);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--chakra-colors-primary_color);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--chakra-colors-secondary_color);
  }
  50% {
    box-shadow: 0 0 0 var(--chakra-colors-secondary_color);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--chakra-colors-secondary_color);
    margin-top: 0;
  }
}

.spinner-label {
  color: var(--chakra-colors-white);
}
