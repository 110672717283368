.profile-image-container {
  display: flex;
  justify-content: center;
  align-content: center;
}
.upload-profile-image-label {
  &:hover {
    opacity: 100%;
  }
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: var(--chakra-colors-white);
  line-height: 30px;
  font-size: 15px;
  cursor: pointer;
  transition: ease-in 0.3s;
  opacity: 0%;
}
.profile-image-picker {
  display: none;
}

.profile-pic-div {
  height: 100px;
  width: 100px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--chakra-colors-profile_image_picker);
}

.profile-user-image {
  height: 100%;
  width: 100%;
}

.default-avatar {
  width: 100% !important;
  height: 100% !important;
  .chakra-avatar__initials {
    font-size: 30px !important;
    font-weight: bold;
  }
}
