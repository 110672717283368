@import "./../../../variables.scss";

$contact-socials-width: 330px;

.footer-container {
  background-color: var(--chakra-colors-primary_color);
  color: white;
  display: flex;
  flex-direction: row;
  padding: 20px;

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
  }

  .left-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
  }
  .right-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }

  .link-with-icon {
    text-decoration: none;
    color: var(--chakra-colors-white);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .link-icon {
        width: 18px;
        height: 18px;
        color: var(--chakra-colors-white);
        fill: var(--chakra-colors-white);
      }
    }
  }
  .socials {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .socials-container {
    width: $contact-socials-width;
    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }
  .contact-container {
    width: $contact-socials-width;
    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }

  .footer-title {
    font-size: 20px;
    font-weight: 600;
  }

  .email-arrow {
    margin-left: 10px;
  }
}
.copyright {
  background-color: var(--chakra-colors-primary_color);
  color: white;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  .powered {
    >.powered-link {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
