@import "./../../../../variables.scss";

//Size of the card
$card-size: 384px;
// Amount of cards in a row
$number-of-columns: 4;

.adm-properties-container {
  .properties-list {
    .card-container {
      display: flex;
      justify-content: center;
    }
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    padding: 20px 20px;
    .property-image {
      width: 384px;
      height: 250px;
      object-fit: cover;
    }
  }
}

.filter-bar {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 72px;
  padding: 20px 3%;
  width: 100%;
  z-index: 8;
  .btn-container {
    display: flex;
    justify-content: flex-end;
  }
  .filters-base {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    .input-container {
      width: 230px;
    }
    .clear-filters-container {
      display: flex;
      max-width: 230px;
      align-items: flex-end;
    }
  }
}

.map-base-property-card {
  display: flex;
  height: 250px;
  width: 100%;
  .map-container {
    width: 100%;
    height: 250px;
    border-radius: var(--chakra-radii-lg);
  }
}

.stamp {
  &.sold {
    color: red;
    border-color: red;
  }
  &.soldByUs {
    color: greenyellow;
    border-color: greenyellow;
    mix-blend-mode: unset;
  }
  &.invisible-s {
    color: white;
    border-color: white;
  }
  color: #555;
  font-size: 2rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");
  mask-size: 944px 604px;
  -webkit-mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: multiply;
  width: 200px;
  text-align: center;
  filter: drop-shadow(0.1em 0.1em 4px rgba(0, 0, 0, 0.5));
}

.stamp-base {
  &.stamp-base-sc {
    background: linear-gradient(
      to right,
      rgba(28, 116, 153, 1) 35%,
      rgba(136, 196, 83, 1) 100%
    );
  }
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  transform: rotate(-12deg);
  position: absolute;
  width: 200px;
  top: calc(50% - 32px);
  left: calc(50% - 100px);
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2)
  );
  backdrop-filter: blur(1rem);
  z-index: 7;
  padding: 5px;
}

.cover-container {
  position: relative;
}

.card-subtitle {
  font-weight: 600;
}

.ref-key {
  margin-bottom: 20px;
  font-size: 14px;
  font-style: italic;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.pagination-container {
  background-color: var(--chakra-colors-white);
  padding: 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 8;
}
