.pagination_container {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  justify-content: flex-end;
}

.item_page {
  margin: 0px 5px;
  border: 1px solid var(--chakra-colors-primary_color) !important;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.item_page > a {
  padding: 0.375rem 0.75rem !important;
}

.break_label {
  font-size: 16px;
  font-weight: bold;
}

.break_label > a {
  padding: 0.375rem 0.75rem !important;
}

// .item_page:hover,
// .page_controller:hover:not(.disabled) {
//   background-color: var(--chakra-colors-light_success);
//   color: var(--chakra-colors-dark_success);
//   font-weight: 800;
// }

.page_controller {
  border: 1px solid var(--chakra-colors-primary_color) !important;
  border-radius: 4px;
  align-self: center;
  height: 30px;
  width: 30px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.disabled {
  display: none;
}

.active,
.active:focus,
.active:hover {
  background-color: var(--chakra-colors-primary_color);
  color: var(--chakra-colors-white);
}
