.new-select {
  &__control {
    &:focus,
    &--is-focused {
      box-shadow: 0 0 0 1px var(--chakra-colors-input_border_color) !important;
      border: 1px solid var(--chakra-colors-input_border_color) !important;
    }
  }
  &__menu {
    z-index: 99999 !important;
  }
  &__option {
    &--is-focused,
    &:focus-visible,
    &:hover {
      background-color: var(--chakra-colors-secondary_color) !important;
    }
    &--is-selected {
      color: 000;
      background-color: var(--chakra-colors-primary_color) !important;
    }
  }
  &__multi-value {
    background-color: var(--chakra-colors-secondary_color) !important;
    color: var(--chakra-colors-primary_color);
    &__label {
      color: var(--chakra-colors-primary_color) !important;
    }
  }
}
.new-select-error {
  &__control {
    box-shadow: 0 0 0 1px var(--chakra-colors-red-500);
    &:focus,
    &--is-focused {
      box-shadow: 0 0 0 1px var(--chakra-colors-input_border_color) !important;
      border: 1px solid var(--chakra-colors-input_border_color) !important;
    }
  }
  &__menu {
    z-index: 99999 !important;
  }
  &__option {
    &--is-focused,
    &:focus-visible,
    &:hover {
      background-color: var(--chakra-colors-secondary_color) !important;
    }
    &--is-selected {
      color: 000;
    }
  }
}

.loading-info {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  z-index: 9999999;
}
