.color-base {
  background-color: var(--chakra-colors-secondary_color) !important;
  &:hover {
    background-color: var(--chakra-colors-primary_button_hover) !important;
  }
}
.color-base-red {
  background-color: var(--chakra-colors-red_button) !important;
  color: var(--chakra-colors-white) !important;
  &:hover {
    background-color: var(--chakra-colors-red_button_hover) !important;
  }
}
