@import "./../../../variables.scss";

.favorites-view-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70vh;

  .favorites-view-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 2rem;
    @include media-breakpoint-down(lg) {
      margin: 2rem 0;
    }

    .title {
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.05em;
      margin: 0;
      @include media-breakpoint-down(lg) {
        font-size: 30px;
      }
    }
    .description {
      font-size: 20px;
      color: var(--chakra-colors-grey_text_color);
      text-align: center;

      @include media-breakpoint-down(lg) {
        font-size: 15px;
      }
    }
  }

  .favorites-list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 236px;
    grid-gap: 1rem;
    padding: 20px 20px;
    width: 100%;
    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: auto;
      padding: 10px;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(2, 1fr);
      padding: 20px;
    }
  }
}

.noFavorites-container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .no-favorites-title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    text-align: center;
    @include media-breakpoint-down(lg) {
      font-size: 30px;
    }
  }
  .no-favorites-desc {
    font-size: 20px;
    color: var(--chakra-colors-grey_text_color);
    text-align: center;

    @include media-breakpoint-down(lg) {
      font-size: 15px;
    }
  }
}
