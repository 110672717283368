@import "./../../../variables.scss";

.visual-profile-base {
  display: flex;
  align-items: center;
  .user-Name {
    border-bottom: 2px solid var(--chakra-colors-white);
    font-weight: 600;
    color: var(--chakra-colors-white);
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.option-base {
  display: flex;
  align-items: center;
}
