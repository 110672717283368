@import "./../../../variables.scss";

.property-container {
  .property-header {
    position: sticky;
    left: 0;
    top: 72px;
    z-index: 8;
    background-color: var(--chakra-colors-white);
    padding: 0.5rem 2rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    @include media-breakpoint-down(lg) {
      padding: 0.5em;
    }
    .pill-favorite {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .type-pill {
        width: fit-content;
        border: 1px solid var(--chakra-colors-grey_text_color);
        color: black;
        border-radius: 9999px;
        padding: 2px 10px;
        font-weight: 600;
        font-size: 12px;
      }

      .favorite {
        padding: 0 10px;
        border: 2px solid orangered;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        gap: 10px;
        border-radius: 8px;
        cursor: pointer;
        color: orangered;
        padding: 5px 10px;
        .favorite-label {
          margin: 0;
        }
      }
    }
    .property-name-status {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding-top: 10px;
      }
      .property-name {
        font-size: 48px;
        font-weight: 600;
        letter-spacing: -0.05em;
        @include media-breakpoint-down(lg) {
          font-size: 20px;
        }
        p {
          margin: 0;
        }
      }
      .property-status {
        width: 150px;
        height: 50px;
        margin: 0px 30px;
        border-radius: 8px;
        color: white;
        font-size: 28px;
        font-weight: 600;
        align-items: center;
        display: flex;
        justify-content: center;
        background: linear-gradient(
          to right bottom,
          rgba(28, 116, 153, 1) 35%,
          rgba(136, 196, 83, 1) 100%
        );
        @include media-breakpoint-down(lg) {
          width: 80px;
          height: 20px;
          margin: 0px;
          border-radius: 4px;
          font-size: 14px;
        }
      }
    }

    .property-price {
      color: var(--chakra-colors-gray-500);
      letter-spacing: -0.05em;
      font-weight: 700;
      font-size: 24px;
      margin-top: -10px;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
      }
    }

    .property-location {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-top: -5px;
      margin-bottom: 8px;
      p {
        margin: 0;
        font-size: 16px;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
      }
    }
    .ref-key-property {
      font-size: 14px;
      font-style: italic;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      width: fit-content;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
      }
    }
  }
  .property-info {
    padding: 2rem;
    @include media-breakpoint-down(lg) {
      padding: 0.5em;
    }

    .features-amenities-media {
      display: flex;
      flex-direction: row;
      margin: 30px 0;
      // justify-content: center;

      .feat-amen-fw {
        width: 100% !important;
      }
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
      }
      .left-section {
        width: 50%;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
      .right-section {
        width: 50%;
        display: flex;
        gap: 40px;
        flex-direction: column;
        justify-content: center;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
      .padding-rs {
        padding: 0 40px;
        @include media-breakpoint-down(lg) {
          padding: 0;
        }
      }
    }
    .property-media {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .property-slides {
        width: 100%;
        height: 500px;
        @include media-breakpoint-down(lg) {
          height: 236px;
        }
        .carousel-item {
          background-color: white;
        }
        .carousel-image {
          width: 100%;
          height: 500px;
          object-fit: cover;
          border-radius: 16px;
          @include media-breakpoint-down(lg) {
            height: 236px;
          }
        }
        .player {
          background-color: white;
        }
        .player-bg {
          background-color: white;
          div {
            video {
              border-radius: 16px;
              object-fit: cover;
            }
          }
        }
        .carousel-control-prev {
          font-size: 28px;
          color: var(--chakra-colors-secondary_color);
          display: flex;
          justify-content: flex-start;
          margin: 0 30px;
        }
        .carousel-control-next {
          font-size: 28px;
          color: var(--chakra-colors-secondary_color);
          display: flex;
          justify-content: flex-end;
          margin: 0 30px;
        }
        .arrow-button {
          border-radius: 50%;
          background-color: white;
          border: 1px solid grey;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
    .property-description {
      .title {
        margin-bottom: 30px;
        letter-spacing: -0.05em;
        font-weight: 600;
      }
      .property-info-description {
        font-size: 18px;
        text-align: justify;
      }
    }

    .property-location {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      .title {
        letter-spacing: -0.05em;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .location-container {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(lg) {
          display: flex;
          flex-direction: column;
        }
        .location-left {
          width: 50%;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
          .location-info {
            .location-data {
              display: flex;
              gap: 5px;
              .location-title {
                font-size: 18px;
                font-weight: 600;
              }
              .location-value {
                font-size: 18px;
              }
            }
            .location-oths {
              .location-titlte {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
              }
              .location-oths-desc {
                font-size: 18px;
              }
            }
          }
        }
        .location-right {
          width: 50%;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
          .map-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .map-property-location {
              display: flex;
              width: 500px;
              height: 300px;
              @include media-breakpoint-down(lg) {
                width: 100%;
                height: 300px;
              }

              .map-container {
                width: 100%;
                height: 300px;
                border-radius: var(--chakra-radii-lg);
                @include media-breakpoint-down(lg) {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }

    .nearby-properties {
      margin-top: 60px;
      .title {
        margin-bottom: 30px;
        letter-spacing: -0.05em;
        font-weight: 600;
      }
      .nearby-properties-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 236px;
        grid-gap: 1rem;
        width: 100%;
        @include media-breakpoint-down(xl) {
          grid-template-columns: repeat(1, 1fr);
          grid-auto-rows: auto;
        }
        @include media-breakpoint-up(xl) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .property-features {
      .title {
        margin-bottom: 30px;
        letter-spacing: -0.05em;
        font-weight: 600;
      }
      .property-features-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .feature-item-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          font-size: 18px;
          p {
            margin: 0;
          }
        }
      }
    }
    .property-amenities {
      .title {
        margin-bottom: 30px;
        letter-spacing: -0.05em;
        font-weight: 600;
      }
      .property-amenities-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .amenitie-item-info {
          .amenitie-icon {
            color: var(--chakra-colors-secondary_color);
            fill: var(--chakra-colors-secondary_color);
            font-size: 18px;
          }
          font-size: 18px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
