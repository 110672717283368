@import "./../../../variables.scss";

.properties-container {
  display: flex;
  width: 100%;
  .properties-data {
    display: flex;
    flex-direction: column;
    width: 80%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    .properties-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem;
      .title {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -0.05em;
        margin: 0;
        @include media-breakpoint-down(lg) {
          font-size: 30px;
        }
      }
      .description {
        font-size: 20px;
        color: var(--chakra-colors-grey_text_color);
        text-align: center;

        @include media-breakpoint-down(lg) {
          font-size: 15px;
        }
      }
    }

    .properties-left-side {
      display: flex;
      flex-direction: column;
      .sort-filter-options {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 10px;
        gap: 30px;
        .sort-options {
          max-width: 300px;
          margin-right: 20px;
        }
      }
    }
    .properties-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 236px;
      grid-gap: 1rem;
      padding: 20px 20px;
      width: 100%;
      @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: auto;
        padding: 10px;
      }
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(2, 1fr);
        padding: 20px;
      }
    }
  }
  .side-panel {
    position: sticky;
    top: 72px;
    right: 0;
    z-index: 8;
    width: 20%;
    height: 100vh;
    box-shadow: -1px -2px 15px -2px rgba(0, 0, 0, 0.57);
    background-color: white;
    .filter-bar {
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 20px 3%;
      width: 100%;
      .filters-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;
        border-bottom: 1px solid var(--chakra-colors-divider_color);
      }
      .filters-base {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 8px;
        .input-container {
          label {
            margin: 0;
          }
          width: 100%;
          height: fit-content;
          .slider-container {
            padding: 0 10px;
          }
        }

        .buttons-container {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 20px;
          align-items: flex-end;
        }
      }
    }
  }
}

.range-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.57);
}

.pagination-container {
  background-color: var(--chakra-colors-white);
  padding: 20px;
  position: relative;
  width: 100%;
  z-index: 8;
  bottom: 0;
}

.select-clear-option {
  width: 100%;
  .remove-icon-container {
    position: absolute;
    right: 0;
    z-index: 7;
    color: hsl(0, 0%, 80%);
    height: 38px;
    display: flex;
    align-items: center;
    margin-right: 46px;
    &:hover {
      color: hsl(0, 0%, 60%);
    }
  }
}

.side-panel-drawer {
  background-color: white;
  > .filter-bar {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 30px 10px;
    padding: 0;
    padding-right: 24px;
    width: 100%;
    .filters-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;
      border-bottom: 1px solid var(--chakra-colors-divider_color);
    }
    .filters-base {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 8px;
      .input-container {
        label {
          margin: 0;
        }
        width: 100%;
        height: fit-content;
        .slider-container {
          padding: 0 10px;
        }
      }

      .buttons-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        align-items: flex-end;
      }
    }
  }
}

.noProperties-container {
  // min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include media-breakpoint-down(lg) {
    width: 100%;
    min-height: 60vh;
  }

  .no-properties-title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    text-align: center;
    @include media-breakpoint-down(lg) {
      font-size: 30px;
    }
  }
  .no-properties-desc {
    font-size: 20px;
    color: var(--chakra-colors-grey_text_color);
    text-align: center;

    @include media-breakpoint-down(lg) {
      font-size: 15px;
    }
  }
}
