@import "./../../../variables.scss";

.about-container {
  margin-top: $top-margin;
  min-height: 92.5vh;
  @include media-breakpoint-up(xl) {
    padding: 40px 0;
  }

  .title {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    @include media-breakpoint-up(xl) {
      padding-top: 30px;
    }
  }

  .mision-vision-container {
    display: flex;

    &.mobile-order {
      @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 30px;
      }
    }

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      padding: 0 60px;
    }

    @include media-breakpoint-down(xl) {
      flex-direction: column;
      padding: 0 20px;
    }

    .description-container {
      font-size: 20px;
      text-align: justify;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      @include media-breakpoint-up(xl) {
        width: 50%;
        padding: 0 60px;
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
        padding: 30px 0;
      }
    }

    .image-container {
      @include media-breakpoint-up(xl) {
        width: 50%;
        padding: 0 60px;
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
        padding: 30px 0;
      }
      img {
        @include media-breakpoint-up(xl) {
          width: 700px;
        }
        @include media-breakpoint-down(xl) {
          width: 100%;
        }
        height: auto;
      }
    }
    .left {
      display: flex;
      justify-content: flex-start;
    }

    .right {
      display: flex;
      justify-content: right;
    }
  }
  .over-margin {
    @include media-breakpoint-up(xl) {
      margin-top: -80px;
    }
  }

  .mision-vision-end-icon {
    margin-top: 80px;
    opacity: 0.4;
    width: 20%;
  }

  .vision-margin {
    @include media-breakpoint-up(xl) {
      margin-top: 80px;
    }
  }

  .services {
    background-color: var(--chakra-colors-secondary_color);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    @include media-breakpoint-up(xl) {
      margin: 0 120px;
      height: 140px;
    }
    @include media-breakpoint-down(xl) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 20px 20px;
      padding: 20px 20px;
      align-items: flex-start;
    }

    .service {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      @include media-breakpoint-up(xl) {
        height: 90px;
      }
      @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .icon-container {
        svg {
          color: var(--chakra-colors-white);
          font-size: 40px;
          @include media-breakpoint-down(xl) {
            font-size: 30px;
          }
        }

        img {
          filter: invert(1);
          width: 40px;
          height: 40px;
          @include media-breakpoint-down(xl) {
            width: 30px;
            height: 30px;
          }
        }
      }
      .service-label {
        color: var(--chakra-colors-white);
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        @include media-breakpoint-down(xl) {
          font-size: 18px;
        }
      }
    }
  }
}
