@import "./../../../../variables.scss";

.card-component-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 236px;
  @include media-breakpoint-down(xl) {
    max-height: unset;
  }
  .mobile-card {
    @include media-breakpoint-down(xl) {
      display: flex;
      flex-direction: column;
    }
    .property-image {
      width: 220px;
      height: 236px;
      object-fit: cover;
      @include media-breakpoint-down(xl) {
        width: 100%;
        height: 236px;
        border-radius: var(--chakra-radii-lg);
      }
    }
    .map-base-property-card {
      display: flex;
      height: 236px;
      width: 220px;
      @include media-breakpoint-down(xl) {
        width: 100%;
        height: 236px;
        // display: none;
      }
      .map-container {
        width: 220px;
        height: 236px;
        border-radius: var(--chakra-radii-lg) 0 0 var(--chakra-radii-lg);
        @include media-breakpoint-down(xl) {
          height: 236px;
          width: 100%;
          border-radius: var(--chakra-radii-lg);
        }
      }
    }
    .ref-key {
      max-width: fit-content;
    }

    .property-cards-quick-actions {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      margin-right: 15px;
      font-size: 24px;
      z-index: 7;
      gap: 20px;
      .favorite-icon {
        fill: orangered;
        color: orangered;
        cursor: pointer;
      }
      .preview-icon {
        color: var(--chakra-colors-primary_color);
        fill: var(--chakra-colors-primary_color);
        cursor: pointer;
      }
    }

    .card-footer-base {
      padding: 8px 15px 8px 1.5rem;
      .card-footer-container {
        width: 100%;
        padding: 8px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        .price-value {
          margin: 0;
        }

        .property-status {
          width: 150px;
          height: 50px;
          margin: 0px 30px;
          border-radius: 8px;
          color: white;
          font-size: 28px;
          font-weight: 600;
          align-items: center;
          display: flex;
          justify-content: center;
          background: linear-gradient(
            to right bottom,
            rgba(28, 116, 153, 1) 35%,
            rgba(136, 196, 83, 1) 100%
          );
          @include media-breakpoint-down(lg) {
            width: 140px;
            height: 40px;
            margin: 0px;
            border-radius: 4px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.modal-body {
  margin-top: 40px;

  .pill-favorite {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    justify-content: flex-end;
    .type-pill {
      width: fit-content;
      border: 1px solid var(--chakra-colors-grey_text_color);
      color: black;
      border-radius: 9999px;
      padding: 2px 10px;
      font-weight: 600;
      font-size: 12px;
    }

    .favorite {
      padding: 0 10px;
      border: 2px solid orangered;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      gap: 10px;
      border-radius: 8px;
      cursor: pointer;
      color: orangered;
      padding: 4px 8px;
      .favorite-label {
        margin: 0;
      }
    }
  }
  .media-modal-container {
    .property-slides {
      width: 100%;
      height: 236px;
      .carousel-item {
        background-color: white;
      }
      .carousel-image {
        width: 100%;
        height: 236px;
        object-fit: cover;
        border-radius: 16px;
      }
      .carousel-control-prev {
        font-size: 14px;
        color: var(--chakra-colors-secondary_color);
        display: flex;
        justify-content: flex-start;
        margin: 0 30px;
      }
      .carousel-control-next {
        font-size: 14px;
        color: var(--chakra-colors-secondary_color);
        display: flex;
        justify-content: flex-end;
        margin: 0 30px;
      }
      .arrow-button {
        border-radius: 50%;
        background-color: white;
        border: 1px solid grey;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .map-base-property-card {
      display: flex;
      height: 236px;
      width: 100%;
      @include media-breakpoint-down(lg) {
        height: 236px;
      }
      .map-container {
        width: 100%;
        height: 236px;
        border-radius: var(--chakra-radii-lg);
        @include media-breakpoint-down(lg) {
          height: 100%;
        }
      }
    }
  }

  .data-modal-container {
    .property-price {
      color: var(--chakra-colors-gray-500);
      letter-spacing: -0.05em;
      font-weight: 700;
      font-size: var(--chakra-fontSizes-md);
      margin-top: -10px;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
      }
    }
    .property-location {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-top: -5px;
      margin-bottom: 10px;
      p {
        margin: 0;
        font-size: 12px;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
      }
    }

    .property-features {
      margin-top: 20px;
      margin-bottom: 20px;
      .title {
        margin-bottom: 16px;
        letter-spacing: -0.05em;
        font-weight: 600;
        font-size: 20px;
      }
      .property-features-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .feature-item-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          font-size: 14px;
          p {
            margin: 0;
          }
        }
      }
    }
    .property-amenities {
      margin-top: 20px;
      margin-bottom: 20px;
      .title {
        margin-bottom: 16px;
        letter-spacing: -0.05em;
        font-weight: 600;
        font-size: 20px;
      }
      .property-amenities-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .amenitie-item-info {
          .amenitie-icon {
            color: var(--chakra-colors-secondary_color);
            fill: var(--chakra-colors-secondary_color);
            font-size: 14px;
          }
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
