@import "./../../../../variables.scss";

.newPropertyModalContainer {
  display: flex;
  flex-direction: column;
}

.add-property-modal {
  transition: all 1s ease-out;
}

.stepper-body {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
}

.places-container {
  position: relative;
  z-index: 10;
  width: 100%;
}

.map-base {
  display: flex;
  gap: 15px;
  flex-direction: column;
  .map-container {
    width: 100%;
    height: 300px;
  }
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.new-property-select {
  &__control {
    &:focus,
    &--is-focused {
      box-shadow: 0 0 0 1px var(--chakra-colors-input_border_color) !important;
      border: 1px solid var(--chakra-colors-input_border_color) !important;
    }
  }
}

.stepper-indicator[data-status="complete"] {
  background-color: var(--chakra-colors-secondary_color) !important;
}
.stepper-indicator[data-status="active"] {
  border-color: var(--chakra-colors-secondary_color) !important;
}
.stepper-separator[data-status="complete"] {
  background-color: var(--chakra-colors-secondary_color) !important;
}

.amenities-features-container {
  height: 400px;
}

.amenities {
  margin: 10px 0;
}

.section-title {
  margin-top: 30px;
}

.seller-contact-info {
  margin-bottom: 30px;
}

.sc_disabled {
  filter: grayscale(1);
}

.col-center {
  display: flex;
  justify-content: center;
}
