@import "./../../../variables.scss";

.login-base {
  display: flex;
  background-color: var(--chakra-colors-white);

  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }
  .left-side {
    width: 45%;
    display: flex;
    justify-content: center;
    background-image: url("https://www.transparenttextures.com/patterns/brushed-alum-dark.png");
    @include media-breakpoint-down(lg) {
      height: 100vh;
      width: 100%;
      padding: 0 20px;
    }
    .form {
      width: 400px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      height: 100%;
      align-items: center;
      .login-button {
        width: 100%;
        background-color: var(--chakra-colors-primary_button);
        &:hover {
          background-color: var(--chakra-colors-primary_button_hover);
        }
      }
      .avatar-img {
        margin-bottom: 20px;
      }
    }
  }
  .right-side {
    width: 55%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    .logo-container {
      background-color: rgba(0, 0, 0, 0.658);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 300px;
        height: 300px;
      }
    }
  }
}

.avatar-img {
  background-color: var(--chakra-colors-avatar_login_color) !important;
}
