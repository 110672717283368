div.language-selector {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 2px;

  &:focus-within:not(.menu-is-open) {
    outline-color: var(--chakra-colors-language_selector_border_color);
    -webkit-transition: outline-color 0.1s ease-in-out;
    -moz-transition: outline-color 0.1s ease-in-out;
    -o-transition: outline-color 0.1s ease-in-out;
    transition: outline-color 0.1s ease-in-out;
  }

  .language-selector {
    &__control {
      &.style-mobile {
        min-width: unset;
      }
      display: flex;
      min-width: 175px;
      min-height: 45px;
      padding-left: 10px;
      padding-right: 10px;
      border: 2px solid transparent;
      cursor: pointer;
      box-shadow: none !important;
      background-color: var(--chakra-colors-secondary_background_color);
      // background-image: url("https://www.transparenttextures.com/patterns/brushed-alum-dark.png");

      &:focus,
      &:focus-within,
      &:hover {
        border-color: var(
          --chakra-colors-language_selector_border_color
        ) !important;
      }

      &--menu-is-open,
      &--menu-is-open:focus,
      &--menu-is-open:focus-within,
      &--menu-is-open:hover {
        border-color: var(
          --chakra-colors-language_selector_border_color
        ) !important;
      }
    }

    &__option {
      display: flex;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;

      &:first-child {
        border-radius: 4px 4px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 4px 4px;
      }

      &--is-selected {
        background-color: var(
          --chakra-colors-language_selector_selected_option_color
        );
        color: var(--chakra-colors-white);
      }

      &:hover:not(.language-selector__option--is-selected),
      &--is-focused:not(.language-selector__option--is-selected) {
        background-color: var(--chakra-colors-language_selector_hover_color);
      }
    }

    &__control,
    &__option {
      .lang-flag {
        width: 26px;
        flex: 0 0 26px;
      }

      .lang-name {
        padding: 2px 0px 0px 8px;
        flex: 1 0 auto;
        font-weight: 600;
      }
    }

    &__value-container,
    &__single-value,
    &__menu {
      margin: 0;
      padding: 0;
    }
    &__single-value {
      color: var(--chakra-colors-white);
    }

    &__menu-list {
      margin: 0;
      padding: 0;
    }
  }

  &.is-loading {
    .language-selector__control {
      & > div {
        color: transparent !important;
        position: relative !important;
      }

      &::after {
        content: "";
        position: absolute;
        height: 25px;
        width: 25px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid var(--chakra-colors-language_selector_border_color);
        border-top-color: "";
        border-radius: 50%;
        animation: button-loading-spinner 0.75s ease infinite;
      }
    }
  }
}

div.dark-language-selector {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 2px;

  &:focus-within:not(.menu-is-open) {
    outline-color: var(--chakra-colors-language_selector_border_color);
    -webkit-transition: outline-color 0.1s ease-in-out;
    -moz-transition: outline-color 0.1s ease-in-out;
    -o-transition: outline-color 0.1s ease-in-out;
    transition: outline-color 0.1s ease-in-out;
  }

  .dark-language-selector {
    &__control {
      &.style-mobile {
        min-width: unset;
      }
      display: flex;
      min-width: 175px;
      min-height: 45px;
      padding-left: 10px;
      padding-right: 10px;
      border: 2px solid transparent;
      cursor: pointer;
      box-shadow: none !important;
      background-color: var(--chakra-colors-secondary_background_color);
      background-image: url("https://www.transparenttextures.com/patterns/brushed-alum-dark.png");

      &:focus,
      &:focus-within,
      &:hover {
        border-color: var(
          --chakra-colors-language_selector_border_color
        ) !important;
      }

      &--menu-is-open,
      &--menu-is-open:focus,
      &--menu-is-open:focus-within,
      &--menu-is-open:hover {
        border-color: var(
          --chakra-colors-language_selector_border_color
        ) !important;
      }
    }

    &__option {
      display: flex;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;

      &:first-child {
        border-radius: 4px 4px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 4px 4px;
      }

      &--is-selected {
        background-color: var(
          --chakra-colors-language_selector_selected_option_color
        );
        color: var(--chakra-colors-white);
      }

      &:hover:not(.dark-language-selector__option--is-selected),
      &--is-focused:not(.dark-language-selector__option--is-selected) {
        background-color: var(--chakra-colors-gray-600);
      }
    }

    &__control,
    &__option {
      .lang-flag {
        width: 26px;
        flex: 0 0 26px;
      }

      .lang-name {
        padding: 2px 0px 0px 8px;
        flex: 1 0 auto;
        font-weight: 600;
      }
    }

    &__value-container,
    &__single-value,
    &__menu {
      margin: 0;
      padding: 0;
    }
    &__menu-list {
      background-color: var(--chakra-colors-gray-700);
      color: var(--chakra-colors-white);
      margin: 0;
      padding: 0;
    }
  }

  &.is-loading {
    .dark-language-selector__control {
      & > div {
        color: transparent !important;
        position: relative !important;
      }

      &::after {
        content: "";
        position: absolute;
        height: 25px;
        width: 25px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid var(--chakra-colors-language_selector_border_color);
        border-top-color: "";
        border-radius: 50%;
        animation: button-loading-spinner 0.75s ease infinite;
      }
    }
  }
}

@keyframes loading-spinner-animation {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
