.togglePassword {
  background-color: var(--chakra-colors-primary_button) !important;
  border: 1px solid var(--chakra-colors-primary_button) !important;
  color: var(--chakra-colors-white);
  &:hover {
    background-color: var(--chakra-colors-primary_button_hover) !important;
    border: 1px solid var(--chakra-colors-primary_button_hover) !important;
  }
}

.input {
  border-color: var(--chakra-colors-input_inactive_border_color) !important;
  &::placeholder {
    color: var(--chakra-colors-placeholder_color) !important;
  }
}

.input-dark-border {
  &:focus-visible {
    border-color: var(--chakra-colors-white) !important;
    box-shadow: 0 0 0 1px var(--chakra-colors-white) !important;
  }
}
.input-light-border {
  &:focus-visible {
    border-color: var(--chakra-colors-input_border_color) !important;
    box-shadow: 0 0 0 1px var(--chakra-colors-input_border_color) !important;
  }
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-tooltip {
  border-style: solid;
  border-color: black transparent transparent transparent;
}
